import React from "react";

export default class Privacy extends React.Component {
    render() {
        return <div className="container p-5">
            <div>
                <div>
                    <div className="row py-5">
                        <div className="col-12">
                            <h1 className="pb-5">Privaatsuspoliitika</h1>
                            <p className="text-justify">Isikuandmete töötlemine<br/><br/>

                                Veebipoe Ludere isikuandmete vastutav töötleja on (reg. kood 16092872) Ludere OÜ, asukohaga Madara tn 14 Tallinn Harjumaa 10612, tel +372 520 9496 ja e-kiri ludere@ludere.ee
                                <br/><br/><br/>

                                Milliseid isikuandmeid töödeldakse:<br/><br/>

                                1) Nimi, telefoninumber ja e-posti aadress<br/>
                                2) Kauba kohaletoimetamise aadress<br/>
                                3) Kaupade ja teenuste maksumus ja maksetega seotud andmed (ostuajalugu)<br/>
                                4) Klienditoe andmed<br/><br/><br/>


                                Mis eesmärgil isikuandmeid töödeldakse<br/><br/>

                                Isikuandmeid kasutatakse kliendi tellimuste haldamiseks ja kauba kohaletometamiseks. Ostuajaloo andmeid (ostu kuupäev, kaup, kogus, kliendi andmed) kasutatakse ostetud kaupade ja teenuste ülevaate koostamiseks ning kliendieelistuste analüüsimiseks. Isikuandmeid nagu e-post, telefoni nr, kliendi nimi, töödeldakse selleks, et lahendada kaupade ja teenuste osutamise seonduvaid küsimusi (klienditugi). Veebipoe kasutaja IP-aadressi või teisi võrguidentifikaatoreid töödeldakse veebipoe kui infoühiskonna teenuse osutamiseks ning veebikasutusstatistika tegemiseks.

                                <br/><br/><br/>
                                Õiguslik alus<br/><br/>

                                Isikuandmete töötlemine toimub kliendiga sõlmitud lepingu täitmise eesmärgil. Isikuandmete töötlemine toimub juriidilise kohustuse täitmiseks (nt raamatupidamine ja tarbijavaidluste lahendamine).

                                Vastuvõtjad, kellele isikuandmed edastatakse

                                Isikuandmed edastatakse veebipoe klienditoele ostude ja ostuajaloo haldamiseks ja kliendiprobleemide lahendamiseks. Nimi, telefoninumber ja e-posti aadress edastatakse kliendi poolt valitud transporditeenuse pakkujale. Kui tegemist on kulleriga kohale toimetatava kaubaga, siis edastatakse lisaks kontaktandmetele ka kliendi aadress. Kui veebipoe raamatupidamine toimub teenusepakkuja poolt, siis edastatakse isikuandmed teenusepakkujale raamatupidamistoimingute tegemiseks. Isikuandmeid võidakse edastada infotehnoloogia teenuste pakkujatele, kui see on vajalik veebipoe funktsionaalsuse või andmemajutuse tagamiseks. Ludere OÜ on isikuandmete vastutav töötleja, Ludere OÜ edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-ile.
                                <br/><br/><br/>

                                Turvalisus ja andmetele ligipääs<br/><br/>

                                Isikuandmeid hoitakse Amazon Web Service (AWS) serverites, mis asuvad Euroopa Liidu liikmseriigi või Euroopa Liidu majanduspiirkonnaga liitunud riikide territooriumil. Andmeid võidakse edastada riikidesse, mille andmekaitse taset on Euroopa Komisjon hinnanud piisavaks ning USA ettevõtetele, kes on liitunud andmekaitse kilbi (Privacy Shield) raamistikuga. Juurdepääs isikuandmetele on veebipoe töötajatel, kes saavad isikuandmetega tutvuda selleks, et lahendada veebipoe kasutamisega seonduvaid tehnilisi küsimusi ning osutada klienditoe teenust. Veebipood rakendab asjakohaseid füüsilisi, organisatsioonilisi ja infotehnilisi turvameetmeid, et kaitsta isikuandmeid juhusliku või ebaseadusliku hävitamise, kaotsimineku, muutmise või loata juurdepääsu ja avalikustamise eest. Isikuandmete edastamine veebipoe volitatud töötlejatele (nt transporditeenuse pakkuja ja andmemajutus) toimub veebipoe ja volitatud töötlejaga sõlmitud lepingute alusel. Volitatud töötlejaid on kohustatud tagama isikuandmete töötlemisel asjakohased kaitsemeetmed.

                                <br/><br/><br/>

                                Isikuandmetega tutvumine ja parandamine<br/><br/>

                                Isikuandmetega saab tutvuda ja teha parandusi veebipoe kasutajaprofiilis. Kui ost on sooritatud ilma kasutajakontota, siis saab tutvuda isikuandmetega klienditoe vahendusel.
                                <br/><br/><br/>

                                Nõusoleku tagasivõtmine<br/><br/>

                                Kui isikuandmete töötlemine toimub kliendi nõusoleku alusel, siis on kliendil õigus nõusolek tagasi võtta teavitades sellest kliendituge e-posti teel.
                                <br/><br/><br/>

                                Säilitamine<br/><br/>

                                Veebipoe kliendikonto sulgemisel kustutatakse isikuandmed, va juhul kui selliseid andmeid on vaja säilitada raamatupidamise jaoks või tarbijavaidluste lahendamiseks. Kui veebipoes on ost sooritatud ilma kliendikontota, siis säilitatakse ostuajalugu kolm aastat. Maksetega ja tarbijavaidlustega seotud vaidluste korral säilitatakse isikuandmed kuni nõude täitmiseni või aegumistähtaja lõpuni. Raamatupidamise jaoks vajalikud isikuandmed säilitatakse seitse aastat.

                                <br/><br/><br/>
                                Kustutamine<br/><br/>

                                Isikuandmete kustutamiseks tuleb võtta ühendust klienditoega e-posti teel. Kustutamistaotlusele vastatakse mitte hiljem kui kuu aja jooksul ning täpsustatakse andmete kustutamise perioodi.
                                <br/><br/><br/>

                                Ülekandmine<br/><br/>

                                E-posti teel esitatud isikuandmete ülekandmise taotlusele vastatakse hiljemalt kuu aja jooksul. Klienditugi tuvastab isikusamasuse ja teavitab isikuandmetest, mis kuuluvad ülekandmisele.
                                <br/><br/><br/>

                                Vaidluste lahendamine<br/><br/>

                                Isikuandmete töötlemisega seotud vaidluste lahendamine toimub klienditoe vahendusel (KONTAKTANDMED). Järelevalveasutus on Eesti Andmekaitse Inspektsioon (info@aki.ee).



                            </p>
                        </div>
                    </div>
                </div>
                <div className="card-body">

                </div>
            </div>
        </div>;
    }
}