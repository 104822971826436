import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./components/home";
import NavMenu from "./components/nav-menu";
import Footer from "./components/footer";
import ProductDetails from "./components/product/product-details";
import {ToastContainer} from "react-toastify";
import ScrollToTop from "vanilli-shop-client/dist/components/scroll-to-top";
import Error404 from "./components/error-404";
import Cart from "./components/cart/cart";
import Checkout from "./components/checkout/checkout";
import CheckoutSuccess from "./components/checkout/checkout-success";
import CheckoutFailure from "./components/checkout/checkout-failure";
import Header from "./components/header";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import BlogPostList from "./components/blog/blog-post-list";
import BlogPostDetails from "./components/blog/blog-post-details";
import routes from "./routes";
import ProductList from "./components/product/product-list";

class App extends React.Component {
    render() {
        return <BrowserRouter>
            <ScrollToTop/>
            <ToastContainer/>
            <div className="container-fullwidth text-center">
                <Header/>
                <NavMenu/>
                <div className="pt-5">
                    <Switch>
                        <Route path="/" exact={true} component={Home}/>
                        <Route path={["/blog", "/blog/posts"]} exact={true} component={BlogPostList}/>
                        <Route path="/blog/posts/:id" component={BlogPostDetails}/>
                        <Route path="/cart" component={Cart}/>
                        <Route path={`${routes.categories}/:id`} component={ProductList}/>
                        <Route path="/checkout" exact={true} component={Checkout}/>
                        <Route path="/checkout/failure" component={CheckoutFailure}/>
                        <Route path="/checkout/success" component={CheckoutSuccess}/>
                        <Route path="/products/:ref" component={ProductDetails}/>
                        <Route path="/terms" component={Terms}/>
                        <Route path="/privacy" component={Privacy}/>
                        <Route component={Error404}/>
                    </Switch>
                </div>
                <Footer/>
            </div>
        </BrowserRouter>;
    }
}

export default App;
