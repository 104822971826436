const routes = {
    root: "/",
    blog: "/blog",
    cart: "/cart",
    categories: "/categories",
    categoriesHash: "/#categories",
    footer: "/#footer",
    privacy: "/privacy",
    terms: "/terms",

    fb: "//facebook.com/JustPrintEstonia",
    instagram: "//instagram.com/justprint_est"
};

export default routes;