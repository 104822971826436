import React from "react";
import {HashLink} from "react-router-hash-link";

export default class Error404 extends React.Component {
    render() {
        return <div className="container-fluid py-5">
            <div className="card">
                <div className="card-header">
                    <div className="row py-5">
                        <div className="col-12">
                            <h3 className="with-divider-red">Lehte ei leitud!</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body py-5">
                    <p>Ups! Tundub, et miskit läks nihu :(</p>
                </div>
                <div className="text-center pb-5">
                    <HashLink className="btn btn-back" to="/">
                        <i className="fas fa-arrow-circle-left pr-2"/>
                        <span className="btn-title">Avalehele</span>
                    </HashLink>
                </div>
            </div>
        </div>;
    }
}