import React from "react";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {CartItem, ICartItem} from "vanilli-shop-client/dist/models/cart";

interface ICartSideProps {
    cartStore: CartStore
}

export default class CartSide extends React.Component<ICartSideProps> {
    render() {
        const cartStore = this.props.cartStore;
        const subTotal = cartStore.calculateSubTotal();
        const shipping = cartStore.calculateShipping();
        const discount = cartStore.calculateDiscount();
        const total = cartStore.calculateTotal();

        return <div className="col-md-12 order-0 pb-5 pb-md-5 pb-lg-0">
            <div className="card">
                <div className="card-header">
                    <h1 className="pt-1">Ostukorv</h1>
                </div>
                <div className="card-body">{cartStore.cartItems.map((item) => CartSide.renderItem(item))}</div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h6>Vahesumma:</h6>
                        </div>
                        <div className="col-md-6 text-right">
                            <h6>{subTotal.toFixed(2)}€</h6>
                        </div>
                    </div>
                    {shipping > 0 && <div className="row">
                        <div className="col-md-6 text-left">
                            <h6>Tarne:</h6>
                        </div>
                        <div className="col-md-6 text-right">
                            <p>{shipping.toFixed(2)}€</p>
                        </div>
                    </div>}
                    {discount && <div className="row">
                        <div className="col-md-6 text-left">
                            <p>Soodustus:</p>
                        </div>
                        <div className="col-md-6 text-right">
                            <p>{discount.toFixed(2)}€</p>
                        </div>
                    </div>}
                    <hr/>
                    <div className="row">
                        <div className="col-md-6 text-left">
                            <h5>Tasuda kokku:</h5>
                        </div>
                        <div className="col-md-6 text-right">
                            <h5>{total.toFixed(2)}€</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private static renderItem(item: ICartItem): JSX.Element {
        const currentPrice = CartItem.getCurrentPrice(item);
        const productName = item.productName;

        return <div key={item.id} className="row py-1">
            <div className="col-3 col-md-2 col-lg-2">
                <img className="img-fluid img-thumbnail" src={item.img} alt={productName}/>
            </div>
            <div className="col-7 text-left">
                <h5>{productName}</h5>
                <p>{currentPrice.toFixed(2)}€</p>
                <p>Kogus: {item.qty}</p>
            </div>
        </div>;
    }
}