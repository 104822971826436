import React from "react";
import Image from "../assets/imgs/bg/DSC_8125 copy.jpg";
import {withRouter} from "react-router-dom";
import routes from "../routes";

interface IProps {
    history: any,
    location: any,
    match: any
}

class Header extends React.Component<IProps> {
    componentDidMount() {
        this.applyBackground();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
        this.applyBackground();
    }

    render() {
        return <div className="header_top">
            <div className="container custom-space">
                <div className="row">
                    <div className="col-12">
                        <div className="header_top_inner d-flex justify-content-between align-items-sm-center">
                            <div className="header_contact_info pl-4">
                                <ul className="d-flex">
                                    <li className="text-white"><i className="fas fa-envelope"/> <a
                                        href="mailto:ludere@ludere.ee">ludere@ludere.ee</a></li>
                                </ul>
                            </div>
                            <div className="free_shipping_text">
                                <p className="mb-0 text-white">Tarne alates 35€ TASUTA!</p>
                            </div>
                            <div className="header_top_sidebar d-flex align-items-center pr-4">
                                <div className="header_social d-flex">
                                    <span>Jälgi meid</span>
                                    <ul className="d-flex">
                                        <li>
                                            <a className="text-decoration-none" rel="noopener noreferrer"
                                               href="//www.facebook.com/JustPrintEstonia" target="_blank">
                                                <i className="icon-social-facebook icons"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="text-decoration-none" rel="noopener noreferrer"
                                               href="//www.instagram.com/justprint_est/" target="_blank">
                                                <i className="icon-social-instagram icons"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    private applyBackground() {
        const style = document.body.style;

        if (this.props.location.pathname.startsWith(routes.categories)) {
            style.backgroundImage = "none";
        } else {
            style.backgroundImage = `url(${Image})`;
        }
    }

}

export default withRouter(Header);