import React from "react";
import {HashLink} from "react-router-hash-link";
import bannerPng from '../assets/imgs/Banner copy.png';
import routes from "../routes";
import CategoryList from "./category-list";

export default class Home extends React.Component {
    render() {
        return <div>
            <div className="min-vh-100">
                <div id="home" className="section-header">
                    <div className="row">
                        <div className="pt-8 text-heading home-center col-11">
                            <div className="animate__animated animate__fadeInUp">
                                <img className="img-fluid img-home" src={bannerPng} alt="Ludere" loading="lazy"/>
                            </div>
                            <h1 className="pt-5 pb-2 col-12">Arendavad raamatud ja mängud lastele otse Eesti
                                tootjalt!</h1>
                            <p>Muutke õppeprotsses mänguliseks lihtsa vaevaga Ludere arendavate raamatute ja
                                mängudega. <br/>Heaks kiidetud ka eripedagoogide poolt. Vaata lähemalt meie blogist!</p>
                            <div id="section03" className="justify-content-center demo pt-9 pb-5">
                                <HashLink to={routes.categoriesHash}><span/><span/><span/></HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="categories" className="section bg-white">
                <CategoryList/>
            </div>
        </div>;
    }
}