import React, {createRef, RefObject} from "react";
import {inject, observer} from "mobx-react";
import ProductDetailsBase, {IProductDetailsBaseProps} from "vanilli-shop-client/dist/components/product-details-base";
import {IProductImgDto} from "vanilli-shop-client/dist/models/product";
import LoaderDetails from "../base/loader/loader-details";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";
import Error404 from "../error-404";
import routes from "../../routes";

@inject('cartStore', 'parameterStore', 'productStore')
@observer
export default class ProductDetails extends ProductDetailsBase<IProductDetailsBaseProps> {
    private readonly formRef: RefObject<HTMLFormElement>;

    constructor(props: any) {
        super(props);
        this.formRef = createRef();
    }

    render() {
        return !this.props.productStore.loading ? this.renderProductResult() : <LoaderDetails/>;
    }

    private renderProductResult(): JSX.Element {
        return this.props.productStore.product ? this.renderProduct() : <Error404/>;
    }

    private renderProduct(): JSX.Element {
        const props = this.props;
        const product = props.productStore.product;
        const cartStore = props.cartStore;

        return <div className="container py-5">
            <div className="text-left pb-3 pt-6">
                <HashLink className="btn btn-back" to={`${routes.categories}/${product?.categoryId}`}><i
                    className="fas fa-arrow-circle-left pr-2"/><span className="btn-title">Tagasi</span></HashLink>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-5 pb-sm-4 pb-md-4">
                    <div id="product-img-carousel" className="carousel slide" data-ride="carousel">
                        <div
                            className="carousel-inner">{product?.imgs?.map((img, idx) => this.renderImg(img, idx))}</div>
                        <a className="carousel-control-prev" href="#product-img-carousel" role="button"
                           data-slide="prev">
                            <span className="fas fa-chevron-left" aria-hidden="true"/>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#product-img-carousel" role="button"
                           data-slide="next">
                            <span className="fas fa-chevron-right" aria-hidden="true"/>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div className="col-md-12 col-lg-7">
                    <div className="card">
                        <form ref={this.formRef}>
                            <div className="card-body">
                                <div className="row py-1">
                                    <div className="col-12">
                                        <h1>{product?.name}</h1>
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col-12">
                                        <div className="pl-5 pr-5"
                                             dangerouslySetInnerHTML={{__html: product?.description || ""}}/>
                                    </div>
                                </div>
                                <div className="row py-1">
                                    <div className="col-12">
                                        <h2>{product?.currentPrice.toFixed(2)}€</h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center py-1">
                                    <div className="col-md-4">
                                        <input type="number" className="form-control" placeholder="Kogus"
                                               required={true} defaultValue={cartStore.selectedQty}
                                               onChange={(e) => this.changeQty(e)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    {product && <div className="col-lg-6 col-md-6 col-sm-12 pt-4 pb-4">
                                        <button className="btn btn-add"
                                                type="button"
                                                onClick={(e) => this.addToCart(e)}>
                                            <i className="fas fa-plus-circle pl-2"/>
                                            <span className="btn-title pl-2">Lisa ostukorvi</span>
                                        </button>
                                    </div>
                                    }
                                    <div className="col-lg-6 col-md-6 col-sm-12 pt-4 pb-4">
                                        <Link className="btn btn-pay"
                                              to="/cart">
                                            <i className="fas fa-shopping-cart pl-2 pr-2"/>
                                            <span className="btn-title">Maksma</span>
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>;
    }

    private addToCart(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            const props = this.props;
            const product = props.productStore.product;

            if (product) {
                props.cartStore.addToCart(product);
            }
        }
    }

    private changeQty(e: React.ChangeEvent<HTMLInputElement>) {
        const qty = Number(e.target.value);
        this.props.cartStore.changeSelectedQty(qty);
    }

    private renderImg(img: IProductImgDto, idx: number): JSX.Element {
        const path = img.path;

        return <div className={`carousel-item ${idx === 0 ? "active" : undefined}`}>
            <img className="d-block w-100" src={path} alt={this.props.productStore.product?.name}/>
        </div>;
    }
}