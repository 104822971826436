import React, {createRef, RefObject} from "react";

interface ICouponProps {
    onApply: (code: string) => void,
    loading?: boolean
}

export default class Coupon extends React.Component<ICouponProps> {
    private readonly codeRef: RefObject<HTMLInputElement>;

    constructor(props: ICouponProps) {
        super(props);
        this.codeRef = createRef();
    }

    render() {
        return <div className="row py-2">
            <div className="col-12">
                <label>Sooduskood</label>
                <div className="row">
                    <div className="col-md-6">
                        <input ref={this.codeRef} type="password" required={true} className="form-control"
                               placeholder="Sooduskood"/>
                    </div>
                    <div className=" col-md-6 pt-3 pt-md-0 pt-lg-0">
                        <button className="btn btn-back" type="button" disabled={this.props.loading}
                                onClick={async (e) => await this.initCouponApply(e)}>
                            <span className="btn-title">Lunasta</span>
                            <i className="fas fa-coins pl-2"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }

    private async initCouponApply(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const codeField = this.codeRef.current;
        const valid = codeField?.reportValidity();

        if (valid) {
            const code = codeField?.value;

            if (code) {
                await this.props.onApply(code);
            }
        }
    }
}