import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import BlogStore from "vanilli-shop-client/dist/stores/blog-store";
import {inject, observer} from "mobx-react";
import LoaderSpinner from "../base/loader/loader-spinner";
import React from "react";
import {IBlogPostDto} from "vanilli-shop-client/dist/models/blog";
import {Link} from "react-router-dom";
import DateUtil from "vanilli-shop-client/dist/utils/date-util";

interface IBlogPostListProps extends IBaseProps {
    blogStore: BlogStore
}

@inject('blogStore')
@observer
export default class BlogPostList extends Base<IBlogPostListProps> {
    componentDidMount() {
        this.props.blogStore.getPosts();
    }

    render() {
        return <div className="section bg-light pt-7">
            <div className="section pt-5 bg-light">
                <div className="container">
                    <h4 className="with-divider-blue">Blogi</h4>
                </div>
                <div className="container component-content">{!this.props.blogStore.loading ? this.renderPostResult() :
                    <LoaderSpinner/>}</div>
            </div>
        </div>;
    }

    private renderPostResult(): JSX.Element {
        return this.props.blogStore.posts.length > 0 ? this.renderPosts() : BlogPostList.renderEmptyResult();
    }

    private renderPosts() {
        return <div className="row">{this.props.blogStore.posts.map((post) => BlogPostList.renderPost(post))}</div>;
    }

    private static renderPost(post: IBlogPostDto): JSX.Element {
        const id = post.id;
        const title = post.title;
        const published = DateUtil.format(post.published);

        return <div key={id} className="container-product col-lg-3 col-md-4 col-sm-6 col-xs-6 pt-4">
            <Link to={`/blog/posts/${id}`}>
                <div className="card card-transparent h-100">
                    <div className="card-body">
                        <img className="img-fluid pb-2" loading="lazy" src={post.imgPath} alt={title}/>
                        <h5>{title}</h5>
                        <p className="f-12">{published}</p>
                    </div>
                </div>
            </Link>
        </div>;
    }

    private static renderEmptyResult(): JSX.Element {
        return <div className="row justify-content-center">
            <h5>Ups! Tundub, et me pole veel midagi postitanud!</h5>
        </div>;
    }
}