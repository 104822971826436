import React from "react";
import navbarBrandPng from '../assets/imgs/NavbarBrand2 copy.png';
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {inject, observer} from "mobx-react";
import routes from "../routes";
import CategoryStore from "vanilli-shop-client/dist/stores/category-store";
import {ICategoryDto} from "vanilli-shop-client/dist/models/category";
import {Link} from "react-router-dom";
import NavUtil from "../utils/nav-util";

interface INavMenuProps extends IBaseProps {
    cartStore?: CartStore,
    categoryStore?: CategoryStore
}

@inject('cartStore', 'categoryStore')
@observer
export default class NavMenu extends Base<INavMenuProps> {
    componentDidMount() {
        this.props.categoryStore?.getCategories();
        window.onscroll = () => NavUtil.adjustNavbarOnScroll();
    }

    render() {
        const props = this.props;

        return <nav id="navbar" className="navbar navbar-expand-lg navbar-light sticky-top">
            <a className="navbar-brand" href={routes.root}>
                <img src={navbarBrandPng} alt="Ludere"/>
            </a>
            <button id="navbarToggler" className="navbar-toggler float-right" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation" onClick={NavUtil.toggleNavbar}>
                <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mx-auto pb-5 pb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link" href={routes.blog}>Blogi</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">Tooted<i
                            className="fas fa-chevron-down pl-2 vertical-align-middle"/></a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {props.categoryStore?.categories.map((category) => NavMenu.renderCategory(category))}
                            <div className="dropdown-divider"/>
                            <a className="dropdown-item" href="mailto:ludere@ludere.ee">Ei leidnud mida otsisite?</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={routes.footer}>Kontakt</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href={routes.cart}>Ostukorv <span
                            className="vertical-align-middle">({props.cartStore?.cartItems.length})</span></a>
                    </li>
                </ul>
            </div>
        </nav>;
    }

    private static renderCategory(category: ICategoryDto) {
        const id = category.id;
        return <Link key={id} className="dropdown-item" to={`${routes.categories}/${id}`}
                     onClick={() => NavUtil.closeNavbar()}>{category.name}</Link>;
    }
}