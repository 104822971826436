import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import BlogStore from "vanilli-shop-client/dist/stores/blog-store";
import {inject, observer} from "mobx-react";
import React from "react";
import {Link} from "react-router-dom";
import DateUtil from "vanilli-shop-client/dist/utils/date-util";

interface IBlogPostDetailsProps extends IBaseProps {
    blogStore: BlogStore
}

@inject('blogStore')
@observer
export default class BlogPostDetails extends Base<IBlogPostDetailsProps> {
    componentDidMount() {
        const props = this.props;
        props.blogStore.getPostById(props.match.params.id);
    }

    render() {
        const post = this.props.blogStore.post;
        const published = post?.published && DateUtil.format(post.published);


        return <div className="container pt-container py-5">
            <div className="row pt-7">
                <div className="col-12">
                    <div className="card">
                            <div className="row pt-5">
                                <div className="col-12">
                                    <h1 className="with-divider-blue">{post?.title}</h1>
                                    <p>Lisatud: {published}</p>
                                </div>
                            </div>
                        <div className="card-body card-body-blog">
                            <div dangerouslySetInnerHTML={{__html: post?.content || ""}}/>
                        </div>
                        <div className="card-footer">
                            <div className="row text-left">
                                <div className="col-12">
                                    <Link className="btn btn-back" to="/blog">
                                        <i className="fas fa-arrow-circle-left pr-2"/>
                                        <span className="btn-title">Tagasi</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}