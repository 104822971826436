import React, {createRef, RefObject} from "react";
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import OrderStore from "vanilli-shop-client/dist/stores/order-store";
import {inject, observer} from "mobx-react";
import CountryStore from "vanilli-shop-client/dist/stores/country-store";
import CartSide from "../cart/cart-side";
import {Link} from "react-router-dom";
import {ICountryDto} from "vanilli-shop-client/dist/models/country";
import ShippingStore from "vanilli-shop-client/dist/stores/shipping-store";
import {IShippingMethodDto} from "vanilli-shop-client/dist/models/shipping";
import CompanyStore from "vanilli-shop-client/dist/stores/company-store";
import {PaymentProviderEnum} from "vanilli-shop-client/dist/models/payment";
import {CompanyProvider} from "vanilli-shop-client/dist/models/company";
import Coupon from "../coupon";
import CouponStore from "vanilli-shop-client/dist/stores/coupon-store";

interface ICheckoutProps extends IBaseProps {
    cartStore: CartStore,
    companyStore: CompanyStore,
    countryStore: CountryStore,
    couponStore: CouponStore,
    orderStore: OrderStore,
    shippingStore: ShippingStore
}

@inject('cartStore', 'companyStore', 'countryStore', 'couponStore', 'orderStore', 'shippingStore')
@observer
export default class Checkout extends Base<ICheckoutProps> {
    private readonly formRef: RefObject<HTMLFormElement>;
    private readonly firstNameRef: RefObject<HTMLInputElement>;
    private readonly lastNameRef: RefObject<HTMLInputElement>;
    private readonly companyRef: RefObject<HTMLInputElement>;
    private readonly emailRef: RefObject<HTMLInputElement>;
    private readonly phoneRef: RefObject<HTMLInputElement>;
    private readonly addressRef: RefObject<HTMLInputElement>;
    private readonly cityRef: RefObject<HTMLInputElement>;
    private readonly countyRef: RefObject<HTMLInputElement>;
    private readonly zipRef: RefObject<HTMLInputElement>;
    private readonly commentRef: RefObject<HTMLTextAreaElement>;

    async componentDidMount() {
        const props = this.props;
        await props.countryStore.getCountries();
        const companyStore = props.companyStore;
        await companyStore.getPaymentProviders();
        companyStore.selectPaymentProvider(PaymentProviderEnum.MakeCommerce);
    }

    constructor(props: ICheckoutProps) {
        super(props);
        this.formRef = createRef();
        this.firstNameRef = createRef();
        this.lastNameRef = createRef();
        this.companyRef = createRef();
        this.emailRef = createRef();
        this.phoneRef = createRef();
        this.addressRef = createRef();
        this.cityRef = createRef();
        this.countyRef = createRef();
        this.zipRef = createRef();
        this.commentRef = createRef();
    }

    render() {
        const props = this.props;
        const countryStore = props.countryStore;
        const orderStore = props.orderStore;
        const shippingStore = props.shippingStore;
        const couponStore = props.couponStore;
        const selectedShippingMethod = shippingStore.selectedMethod;

        return <div className="container pt-container py-5">
            <div className="row">
                <div className="col-md-12 order-1 pt-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h1 className="pt-1">Tellimuse vorm</h1>
                                </div>
                                <div className="card-body text-left">
                                    <form ref={this.formRef}>
                                        <div className="row py-2">
                                            <div className="col-md-6 col-lg-4">
                                                <label>Eesnimi</label>
                                                <input ref={this.firstNameRef} type="text" className="form-control"
                                                       required={true} />
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <label>Perekonnanimi</label>
                                                <input ref={this.lastNameRef} type="text" className="form-control"
                                                       required={true} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <label>Ettevõtte nimi (valikuline)</label>
                                                <input ref={this.companyRef} type="text" className="form-control"
                                                />
                                            </div>
                                        </div>
                                        <div className="row py-2">

                                        </div>
                                        <div className="row py-2">
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <label>E-posti aadress</label>
                                                <input ref={this.emailRef} type="email" className="form-control"
                                                       required={true} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <label>Telefon</label>
                                                <input ref={this.phoneRef} type="tel" className="form-control"
                                                       required={true} />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-4">
                                                <label>Riik</label>
                                                <select className="form-control" required={true}
                                                        disabled={countryStore.loading}
                                                        onChange={async (e) => await this.selectCountry(e)}>{this.renderCountries()}</select>
                                            </div>
                                        </div>

                                        <div className="row py-2">

                                        </div>
                                        <div className="row py-2">

                                        </div>
                                        {countryStore.selectedCountry && <div className="row py-2">
                                            <div className="col-12">
                                                <label>Tarneviis</label>
                                                <select className="form-control" required={true}
                                                        disabled={shippingStore.loading}
                                                        onChange={async (e) => await this.selectShippingMethod(e)}>{this.renderShippingMethods()}</select>
                                            </div>
                                        </div>}
                                        {selectedShippingMethod?.provider && <div className="row py-2">
                                            <div className="col-12">
                                                <label>Pakiautomaat</label>
                                                <select className="form-control" required={true}
                                                        disabled={shippingStore.loading}
                                                        onChange={(e) => shippingStore.selectPickupPoint(e.target.value)}>{this.renderPickupPoints()}</select>
                                            </div>
                                        </div>}
                                        {selectedShippingMethod?.addressRequired && <>
                                            <div className="row py-2">
                                                <div className="col-12">
                                                    <label>Address</label>
                                                    <input className="form-control" ref={this.addressRef} type="text"
                                                           required={true} />
                                                </div>
                                            </div>
                                            <div className="row py-2">
                                                <div className="col-12">
                                                    <label>Linn</label>
                                                    <input className="form-control" ref={this.cityRef} type="text"
                                                           required={true} />
                                                </div>
                                            </div>
                                            <div className="row py-2">
                                                <div className="col-12">
                                                    <label>Maakond</label>
                                                    <input className="form-control" ref={this.countyRef} type="text"
                                                           required={true} />
                                                </div>
                                            </div>
                                            <div className="row py-2">
                                                <div className="col-12">
                                                    <label>Postiindeks</label>
                                                    <input className="form-control" ref={this.zipRef} type="text"
                                                           required={true} />
                                                </div>
                                            </div>
                                        </>}
                                        <div className="row py-2">
                                            <div className="col-12">
                                                <label>Kommentaar</label>
                                                <textarea ref={this.commentRef} className="form-control"
                                                           rows={5}/>
                                            </div>
                                        </div>
                                    </form>
                                    <Coupon onApply={async (code) => await couponStore.applyCoupon(code)}
                                            loading={couponStore.saving}/>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-6 pb-4">
                                            <Link className="btn btn-back"
                                                  to="/cart">
                                                <i className="fas fa-arrow-circle-left pr-2"/>
                                                <span className="btn-title">Tagasi</span>
                                            </Link>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn btn-pay"
                                                    disabled={orderStore.saving || orderStore.loading} type="button"
                                                    onClick={async (e) => await this.initOrderCreate(e)}>
                                                <i className="fas fa-check-circle pr-2"/>
                                                <span className="btn-title">Kinnita</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CartSide cartStore={props.cartStore}/>
            </div>
        </div>;
    }

    private renderCountries(): JSX.Element {
        return <>
            <option value="">-- Vali riik --</option>
            {this.props.countryStore.countries.map((country) => Checkout.renderCountry(country))}
        </>;
    }

    private static renderCountry(country: ICountryDto): JSX.Element {
        const id = country.id;
        return <option key={id} value={id}>{country.name}</option>;
    }

    private renderShippingMethods(): JSX.Element {
        return <>
            <option value="">-- Vali tarneviis --</option>
            {this.props.shippingStore.methods.map((method) => Checkout.renderShippingMethod(method))}
        </>;
    }

    private static renderShippingMethod(method: IShippingMethodDto): JSX.Element {
        const id = method.methodId;
        return <option key={id} value={id}>{method.methodName}</option>;
    }

    private renderPickupPoints(): JSX.Element {
        return <>
            <option value="">-- Vali pakiautomaat</option>
            {this.props.shippingStore.pickupPoints.map((pickupPoint) => Checkout.renderPickupPoint(pickupPoint))}
        </>
    }

    private static renderPickupPoint(pickupPoint: string): JSX.Element {
        return <option key={pickupPoint} value={pickupPoint}>{pickupPoint}</option>;
    }

    private async initOrderCreate(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            await this.createOrder();
        }
    }

    private async createOrder() {
        const order = {
            comment: this.commentRef.current?.value,
            customer: {
                firstName: this.firstNameRef.current?.value,
                lastName: this.lastNameRef.current?.value,
                companyName: this.companyRef.current?.value,
                email: this.emailRef.current?.value,
                phone: this.phoneRef.current?.value
            },
            shipping: {
                address: this.addressRef.current?.value,
                city: this.cityRef.current?.value,
                county: this.countyRef.current?.value,
                zip: this.zipRef.current?.value
            }
        };

        const props = this.props;
        const result = await props.orderStore.createOrder(order);

        if (result) {
            const paymentProvider = props.companyStore.selectedPaymentProvider;

            if (paymentProvider?.name === PaymentProviderEnum.MakeCommerce) {
                const shopId = CompanyProvider.getShopId(paymentProvider);
                const mcUrl = `${process.env.REACT_APP_MAKE_COMMERCE_ENDPOINT}?shop=${shopId}&amount=${result.total}&reference=${result.orderCode}`;
                window.open(mcUrl, "_self");
            }
        }
    }

    private async selectCountry(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        await this.props.countryStore.selectCountry(id);
    }

    private async selectShippingMethod(e: React.ChangeEvent<HTMLSelectElement>) {
        const id = Number(e.target.value);
        await this.props.shippingStore.selectMethod(id);
    }
}