import React from "react";
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {inject, observer} from "mobx-react";

interface ICheckoutSuccess extends IBaseProps {
    cartStore: CartStore
}

@inject('cartStore')
@observer
export default class CheckoutSuccess extends Base<ICheckoutSuccess> {
    componentDidMount() {
        this.props.cartStore.resetCart();
    }

    render() {
        return <div className="container pt-container py-5">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row py-5">
                                <div className="col-12">
                                    <h3 className="with-divider-blue">Tellimus kinnitatud!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-8">
                            <p>Täname teid tellimuse eest!</p>
                            <p>Tellimuse kinnitus on saadetud Teie e-postile. Tellimus jõuab teie soovitud pakiautomaati 3-7 tööpäeva jooksul.</p>
                            <p>Leia põnevaid viise kuidas kasutada Ludere tooteid, loe lähemalt meie <a href="/blog"><strong>blogist!</strong></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}