import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import CategoryStore from "vanilli-shop-client/dist/stores/category-store";
import LoaderSpinner from "./base/loader/loader-spinner";
import {Link} from "react-router-dom";
import React from "react";
import {ICategoryDto} from "vanilli-shop-client/dist/models/category";
import routes from "../routes";
import {inject, observer} from "mobx-react";

interface IProps extends IBaseProps {
    categoryStore?: CategoryStore
}

@inject('categoryStore')
@observer
export default class CategoryList extends Base<IProps> {
    render() {
        return <div className="section bg-light">
            <div className="section pt-5 bg-light pb-5">
                <div className="container">
                    <h4 className="with-divider-blue">Tootekataloog</h4>
                </div>
                <div
                    className="container component-content">{!this.props.categoryStore?.loading ? this.renderCategories() :
                    <LoaderSpinner/>}</div>
            </div>
        </div>;
    }

    private renderCategories(): JSX.Element {
        return <div
            className="row">{this.props.categoryStore?.categories.map((category) => CategoryList.renderCategory(category))}</div>;
    }

    private static renderCategory(category: ICategoryDto): JSX.Element {
        const id = category.id;
        const name = category.name;

        return <div className="container-product col-lg-3 col-md-4 col-sm-6 col-6 pt-4" key={id}>
            <Link to={`${routes.categories}/${id}`}>
                <div className="card-transparent h-100">
                    <div className="card-body">
                        <img className="img-fluid pb-2" loading="lazy" src={category.img} alt={name}/>
                        <div className="card-divider pt-2"/>
                        <h5 className="pt-2">{name}</h5>
                    </div>
                </div>
            </Link>
        </div>;
    }
}