import React from "react";
import {Link} from "react-router-dom";
import footerBrandPng from '../assets/imgs/footer-img.png';

export default class Footer extends React.Component {
    render() {
        return <footer id="footer" className="footer-distributed">
            <div className="footer-left">
                <div className="col-4 offset-4 col-md-3 offset-md-0 p-0 footer-brand">
                    <img src={footerBrandPng} alt="Footer brand"/>
                </div>
                <br/>
                <p>Ludere OÜ</p>
                <p>Reg. kood: 16092872</p>
                <br/>
                <p><Link to="/terms" target="_blank">Müügitingimused</Link></p> <p> <Link to="/privacy"
                                                                                    target="_blank">Privaatsuspoliitika</Link></p>
                <br/>
                <p className="footer-company-name">Ludere &copy; 2021</p>
            </div>
            <div className="footer-center text-left">
                <div>
                    <a rel="noopener noreferrer" target="_blank"
                       href="//www.google.com/maps/place/Madara+14,+10613+Tallinn/@59.431732,24.7175459,17z/data=!3m1!4b1!4m5!3m4!1s0x46929485d33ef7c5:0x628593a3919dfb39!8m2!3d59.4317293!4d24.7197346">
                        <i className="fa fa-map-marker pl-1"/><span>Madara 14, Tallinn, Harjumaa 10612</span>
                    </a>
                </div>
                <div>
                    <a href="mailto:ludere@ludere.ee">
                        <i className="fa fa-envelope pl-1"/><span>ludere@ludere.ee</span>
                    </a>
                </div>
                <div>
                    <a href="tel:+3725209496">
                        <i className="fa fa-phone pl-1"/><span>+372 520 9496</span>
                    </a>
                </div>
            </div>
            <div className="footer-right">
                <p className="footer-company-about">
                    <span>Meist</span>
                    Õpetlikud ja arendavad mängud lastele otse Eesti tootjalt.
                </p>
                <div className="footer-icons">
                    <a rel="noopener noreferrer" href="//www.facebook.com/JustPrintEstonia" target="_blank">
                        <i className="fa fa-facebook"/>
                    </a>
                    <a rel="noopener noreferrer" href="//www.instagram.com/justprint_est/" target="_blank">
                        <i className="fa fa-instagram"/>
                    </a>
                </div>
            </div>
        </footer>;
    }
}