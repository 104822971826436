import React from "react";
import LoaderSpinner from "./loader-spinner";

export default class LoaderDetails extends React.Component {
    render() {
        return <div className="container-fluid">
            <div className="row py-5">
                <div className="col-12">
                    <LoaderSpinner/>
                </div>
            </div>
        </div>
    }
}