import React, {createRef, RefObject} from "react";
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import CartStore from "vanilli-shop-client/dist/stores/cart-store";
import {inject, observer} from "mobx-react";
import {CartItem, ICartItem} from "vanilli-shop-client/dist/models/cart";
import {HashLink} from "react-router-hash-link";
import routes from "../../routes";

interface ICartProps extends IBaseProps {
    cartStore: CartStore
}

@inject('cartStore')
@observer
export default class Cart extends Base<ICartProps> {
    private readonly formRef: RefObject<HTMLFormElement>;

    constructor(props: ICartProps) {
        super(props);
        this.formRef = createRef();
    }

    render() {
        const cartStore = this.props.cartStore;
        const total = cartStore.calculateSubTotal();

        return <div className="container pt-container p-5 pt-7">
            <div className="card">
                <div className="card-header">
                    <div className="row py-5">
                        <div className="col-12">
                            <h3 className="with-divider-blue">Sinu ostukorv</h3>
                        </div>
                    </div>
                </div>
                <div className="card-body ">
                    <div className="row py-5 justify-content-center justify-content-md-start justify-content-lg-start">
                        <div className="col-8">{cartStore.cartItems.map((item) => this.renderItem(item))}</div>
                    </div>
                </div>
                <div className="card-footer text-right">
                    <form ref={this.formRef}>
                        <div className="row py-4">
                            <div className="col-12">
                                <h5>Summa: {total.toFixed(2)}€</h5>
                            </div>
                        </div>
                        <div className="row py-1 pb-sm-4 pb-md-4">
                            <div className="col-12">
                                <input type="checkbox" required={true}/>
                                <label className="px-1">Nõustun <a href={routes.terms}>müügitingimustega</a> ja <a
                                    href={routes.privacy}>privaatsuspoliitikaga</a>.</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left">
                                <HashLink className="btn btn-back"
                                          to="/#products">
                                    <i className="fas fa-arrow-circle-left pr-2"/>
                                    <span className="btn-title">Tagasi</span>
                                </HashLink>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-pay"
                                        onClick={(e) => this.initCheckout(e)}>
                                    <span className="btn-title">Jätka</span>
                                    <i className="fas fa-arrow-circle-right pl-2"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>;
    }

    private renderItem(item: ICartItem): JSX.Element {
        const currentPrice = CartItem.getCurrentPrice(item);
        const id = item.id;
        const productName = item.productName;

        return <div key={id} className="row py-1">
            <div className="col-md-4">
                <img className="img-fluid img-thumbnail" src={item.img} alt={productName}/>
            </div>
            <div className="col-md-8 text-left">
                <div className="row">
                    <div className="col-12">
                        <h6>{productName}</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5>{currentPrice.toFixed(2)}€</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h5>Kogus: {item.qty}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-remove" type="button"
                                onClick={() => this.props.cartStore.removeItem(id)}>
                            <span className="btn-title">Eemalda</span>
                            <i className="fas fa-times-circle pl-2"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }

    private initCheckout(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const valid = this.formRef.current?.reportValidity();

        if (valid) {
            this.props.history.push("/checkout");
        }
    }
}