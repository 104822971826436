import React from "react";

export default class NavUtil extends React.Component {
    private static readonly navbarId = "navbar";
    private static readonly navbarSupportedContentId = "navbarSupportedContent";
    private static readonly lightClass = "bg-light";
    private static readonly transparentClass = "bg-transparent";
    private static readonly showClass = "show";

    static adjustNavbarOnScroll() {
        const navbar = document.getElementById(this.navbarId);

        if (!navbar) {
            return;
        }

        if (window.scrollY > 0) {
            if (!navbar.classList.contains(this.lightClass)) {
                this.addBackground();
            }
        } else {
            const navbarSupportedContent = document.getElementById(this.navbarSupportedContentId);

            if (!navbarSupportedContent) {
                return;
            }

            const navbarExpanded = navbarSupportedContent.classList.contains(this.showClass);

            if (!navbarExpanded) {
                this.removeBackground();
            }
        }
    }

    static toggleNavbar() {
        const navbarExpanded = document.getElementById("navbarToggler")?.attributes.getNamedItem("aria-expanded")?.value;

        if (navbarExpanded === "true") {
            NavUtil.adjustNavbar();
        } else {
            NavUtil.resetNavbarAdjustments();
        }
    }

    static closeNavbar() {
        NavUtil.resetNavbarAdjustments();
    }

    private static resetNavbarAdjustments() {
        const navbar = document.getElementById(this.navbarId);

        if (!navbar?.classList.contains(this.transparentClass) && window.scrollY === 0) {
            this.removeBackground();
        }
    }

    private static adjustNavbar() {
        const navbar = document.getElementById(this.navbarId);

        if (!navbar?.classList.contains(this.lightClass)) {
            this.addBackground();
        }
    }

    private static addBackground() {
        const navbar = document.getElementById(this.navbarId);
        navbar?.classList.add(this.lightClass);
        navbar?.classList.remove(this.transparentClass);
    }

    private static removeBackground() {
        const navbar = document.getElementById(this.navbarId);
        navbar?.classList.add(this.transparentClass);
        navbar?.classList.remove(this.lightClass);
    }
}