import React from "react";

export default class CheckoutFailure extends React.Component {
    render() {
        return <div className="container pt-container py-5">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row py-5">
                                <div className="col-12">
                                    <h3 className="with-divider-red">Makse ebaõnnestus!</h3>
                                </div>
                            </div>
                        </div>
                        <div className="card-body py-8">
                            <p>Ups! Tundub, et miskit läks nihu meie maksetöötleja juures.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}