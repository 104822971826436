import React from "react";
import ProductStore from "vanilli-shop-client/dist/stores/product-store";
import Base, {IBaseProps} from "vanilli-shop-client/dist/components/base";
import {inject, observer} from "mobx-react";
import {IProductBaseDto, IProductDto, Product} from "vanilli-shop-client/dist/models/product";
import {Link} from "react-router-dom";
import LoaderSpinner from "../base/loader/loader-spinner";
import CategoryStore from "vanilli-shop-client/dist/stores/category-store";
import routes from "../../routes";
import {HashLink} from "react-router-hash-link";

interface IProductListProps extends IBaseProps {
    categoryStore: CategoryStore,
    productStore: ProductStore
}

@inject('categoryStore', 'productStore')
@observer
export default class ProductList extends Base<IProductListProps> {
    componentDidMount() {
        const props = this.props;
        const id = props.match.params.id;
        props.categoryStore.getCategoryById(id);

        const request = {
            categoryId: id,
            active: true
        };

        props.productStore.searchProducts(request);
    }

    componentDidUpdate(prevProps: Readonly<IProductListProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            window.location.reload();
        }
    }

    render() {
        const props = this.props;

        return <div className="section bg-light">
            <div className="section pt-5 bg-light pb-5">
                <div className="container">
                    <div className="text-left">
                        <HashLink to={routes.categoriesHash} className="btn btn-back"><span
                            className="fas fa-arrow-circle-left pr-2"/>Tagasi</HashLink>
                    </div>
                    <div className="pt-5">
                        <h4 className="with-divider-blue">{props.categoryStore.category?.name}</h4>
                    </div>
                </div>
                <div
                    className="container component-content">{!props.productStore.loading ? this.renderProductResult() :
                    <LoaderSpinner/>}</div>
            </div>
        </div>;
    }

    private renderProductResult(): JSX.Element {
        const productList = this.props.productStore.productList;
        return productList?.count && productList.count > 0 ? this.renderProducts() : ProductList.renderEmptyResult();
    }

    private static renderEmptyResult(): JSX.Element {
        return <div className="row text-center">
            <h4>Ups! Tundub, et oleme läbi müüdud!</h4>
        </div>;
    }

    private renderProducts(): JSX.Element {
        return <div
            className="row">{this.props.productStore.productList.entities.map((product) => ProductList.renderProduct(product))}</div>;
    }

    private static renderProduct(product: IProductDto): JSX.Element {
        const hasStock = Product.hasStock(product);

        return <div className="container-product col-lg-3 col-md-4 col-sm-6 col-6 pt-4"
                    key={product.ref}>{hasStock ? ProductList.renderStockProduct(product) : ProductList.renderOutOfStockProduct(product)}</div>;
    }

    private static renderStockProduct(product: IProductDto): JSX.Element {
        const link = Product.getLink(product);
        const name = product.name;

        return <Link to={link}>
            <div className="card border-0 h-100">
                <div className="card-body">
                    <img className="img-fluid pb-2" loading="lazy" src={product.img} alt={name}/>
                    <div className="card-divider pt-2"/>
                    <h5 className="pt-2">{name}</h5>
                    {ProductList.renderPrice(product)}
                </div>
            </div>
        </Link>;
    }

    private static renderOutOfStockProduct(product: IProductDto): JSX.Element {
        const name = product.name;

        return <div className="card border-0 h-100">
            <div className="card-body">
                <img className="img-fluid pb-2" loading="lazy" src={product.img} alt={name}/>
                <div className="card-divider pt-2"/>
                <h5 className="pt-2">{name}</h5>
                {ProductList.renderPrice(product)}
                <div className="no-stock">
                    <h5 className="card-text pt-8">Hetkel otsas</h5>
                </div>
            </div>
        </div>;
    }

    private static renderPrice(product: IProductBaseDto): JSX.Element {
        const discountPrice = product.discountPrice;
        const price = product.price;
        const currentPrice = discountPrice || price;

        if (discountPrice) {
            return ProductList.renderDiscountPrice(discountPrice, price);
        }

        return ProductList.renderDefaultPrice(currentPrice);
    }

    private static renderDiscountPrice(discountPrice: number, price: number): JSX.Element {
        return <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <del>{price.toFixed(2)}€</del>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h6 className="text-danger text-right">{discountPrice.toFixed(2)}€</h6>
                    </div>
                </div>
            </div>
        </div>;
    }

    private static renderDefaultPrice(price: number): JSX.Element {
        return <div>
            <h6>{price.toFixed(2)}€</h6>
        </div>;
    }
}
